import axios from 'axios'
import router from '@router'
import { Message } from 'view-design'
import Storage from "./localStorage"
import constants from "../constants/constants"
// import store from '@/store'
// import common from './common'

const storage = new Storage()
const CONSTANTS = constants

const getAuth = function (config) {
  let token = storage.get(CONSTANTS.EOVA_AUTH);
  if (!token && !config.url.includes('login') && !config.url.includes('gameStatistics')) {
    router.push({
      url: '/login'
    })
    return;
  }
  return token;
};

let isFlag = true, baseURL = "";   //true为测试环境    false为正式环境
if (isFlag) {
  baseURL = "https://test.api.dougongapp.com";         //测试环境
} else {
  baseURL = "https://api.dougongapp.com";         //正式环境
}
// create an axios instance
const service = axios.create({
  baseURL: baseURL,

  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
  withCredentials:true,

})

// error response handle centralized
function handleErrorCode (res) {
  switch (res.code) {
    case '201':
      Message.warning(res.message || res.msg || 'Error');
      // router.push({
      //   name: 'login'
      // })
      break;
    case '6000':
      // session 失效处理
      router.push({
        name: 'login'
      })
      Message.error(res.message || res.msg || 'Error')
      break;

    case '001004':
    // session 失效处理
    router.push({
      name: 'login'
    })
    Message.error(res.message || res.msg || 'Error')
    break;

    default:
      Message.error(res.message || res.msg || 'Error')
      break;
  }
}

// request interceptor
service.interceptors.request.use(
  config => {
    const token = getAuth(config)
    if (token) {
      config.headers['EOVA_Authorization'] = token
      config.headers['accessToken'] = token.accessToken
    }
    // config.headers['Origin'] = 'https://test.portal.dougongapp.com'

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor

//备注一下  $fetch请求的才会走这里  $axios的都不会走这里
service.interceptors.response.use(
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code == 200 || res.code == 0) {
      return res
      // return Promise.reject(new Error(res.message || 'Error'))
    }else if((res.msg == "登录失效")&&(res.code ==-1)){
      router.push({
        name: 'login'
      })
      Message.error(res.message || res.msg || 'Error')
    }else {
      handleErrorCode(res)
    }
  },
  error => {
    Message.error(error || 'Error')
    return Promise.reject(error)
  }
)

export default service
